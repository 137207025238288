/* App.css */
.body, .html, .app, .home-container, .about-page {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.background-image-container, .about-background-container {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.footer-custom {
  text-align: center;
  background: #001529;
  color: #fff;
  padding: 20px 50px;
  margin-top: auto; /* 确保footer在页面底部 */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #001529; /* 与footer一致的背景色 */
}



.ant-menu-dark .ant-menu-item-selected {
  background-color: #1890ff; /* 示例选中项颜色 */
}

.ant-btn-text {
  color: white; /* 确保按钮文本在深色背景上可见 */
}

.ant-btn-text:hover {
  color: #1890ff !important; /* 按钮悬停颜色 */
}
/* 新增代码 */
/* App.css */
@media (max-width: 768px) {
  .header {
    justify-content: flex-start; /* 将内容靠左对齐 */
    padding: 0 0px; /* 确保 header 左右没有填充 */
  }

  .header .ant-menu-horizontal {
    flex: 1;
    margin: 0; /* 确保菜单项没有外边距 */
  }

  .header .ant-menu-item {
    margin: 0; /* 确保菜单项没有外边距 */
  }

  .header .ant-btn-text {
    margin-left: auto; /* 确保按钮在右侧 */
  }
}
